<template>
    <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-5 pt-0 relative">
            <v-app-bar
                :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
                :dark="$vuetify.theme.dark"
                class="pt-1"
                fixed
                flat
            >
                <div class="d-flex justify-space-between align-center w-full">
                    <h6 class="ma-0">
                        {{ $t('userInfo.myAccount') }}
                    </h6>
                    <slot name="userDrawerCloseButton" />
                </div>
            </v-app-bar>
        </div>

        <div class="pa-5 pb-1 mt-2">
            <div class="heading-label pa-0 mb-0 mt-5">
                {{ $t('userInfo.profile') }}

                <v-btn
                    x-large
                    icon
                    color=""
                    @click="$router.push('/app/pages/profile')"
                >
                    <v-icon
                        dense
                    >
                        mdi-account-edit
                    </v-icon>
                </v-btn>
            </div>

            <div class="d-flex align-center mb-3">
                <v-avatar
                    size="56"
                    class="mr-2"
                    color="secondary"
                >
                    <img
                        v-if="getUserPicture"
                        :src="getUserPicture"
                        alt=""
                    >
                    <v-icon
                        v-else
                        color="white"
                        v-text="getUserAcronym"
                    />
                </v-avatar>
                <div>
                    <a
                        class="link-alt"
                        @click="$router.push('/app/pages/profile')"
                    >
                        <p class="font-weight-medium ma-0 ">{{ getUser.email }}</p>
                    </a>
                    <p class="text-body-2 text--disabled ma-0 ">
                        {{ `${getUser.name} ${getUser.lastname}` }}
                    </p>
                    <p class="font-weight-medium mb-2">
                        {{ getUser.username }}
                    </p>
                </div>
            </div>

            <div
                v-if="getConfigKeyValue.votation.isOpen"
                class="mt-8"
            >
                <span class="heading-label">{{ $t('statistic.coins.withoutSpend', {count: getCoinsWhithoutSpend, plural: getCoinsWhithoutSpend === 1 ? '' : 's'}) }}</span>
                <myActiveCoins />
            </div>

            <!--          Puntos alcanzados        -->
            <!--            <div class="heading-label pa-0 mb-3">
                {{ $t('statistic.coins.pointsTitle') }}
            </div>
            <div class="mb-0">
                <div
                    v-for="(item, index) in getItems"
                    :key="index"
                >
                    <v-divider
                        v-if="item.totalItem"
                        class="mt-1 mb-2"
                        inset
                        style="width: 80%"
                    />

                    <div
                        class="d-flex"
                    >
                        <v-avatar
                            class="mr-2"
                            :color="item.progressColor"
                            size="36"
                        >
                            <span class="white&#45;&#45;text text-20">{{
                                item.letter
                            }}</span>
                        </v-avatar>
                        <div class="flex-grow-1">
                            <p class="font-weight-semi ma-0 ">
                                {{ item.title }}
                            </p>
                            <p
                                v-if="item.subTitle"
                                class="text&#45;&#45;disabled text-caption"
                            >
                                {{ item.subTitle }}
                            </p>
                        </div>

                        &lt;!&ndash;                      Progress Bar        &ndash;&gt;
                        <div
                            v-if="!item.likeItem && !item.totalItem"
                            class="flex-grow-1"
                            style="min-width: 50px"
                        >
                            <p class="ma-0 text-right">
                                {{ item.progressbarText }}
                            </p>
                            <v-progress-linear
                                :color="item.progressColor"
                                :value="item.progressvalue"
                                rounded
                            />
                        </div>

                        &lt;!&ndash;                      LIKE / DISLIKE   &ndash;&gt;
                        <div
                            v-if="item.likeItem "
                            class="flex-grow-1 pl-1 ml-2 mr-1"
                            style="min-width: 50px; display: flex; justify-content: flex-end;"
                        >
                            <div>
                                <v-icon
                                    color="danger"
                                    x-large
                                    @click="openStatistic"
                                >
                                    mdi-chart-box-plus-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="text-center mb-2 mt-14">
                {{ $t('global.needHelp') }}
                <a
                    class="link-alt"
                    :href="'mailto:' + $t('global.emailSupport')"
                >
                    <p class="font-weight-medium ma-0 ">{{ $t('global.emailSupport') }}</p>
                </a>
            </div>

            <!--            <div class="text-center mb-1">
                <a
                    class="link-alt"
                    @click="$router.push('/app/pages/legalBasesAccepted')"
                >
                    <p class="font-weight-medium ma-0 ">{{ $t('terms.legalBasesContestLink') }}</p>
                </a>
            </div>-->

            <div class="text-center">
                <a
                    class="link-alt"
                    @click="$router.push('/app/pages/termsAccepted')"
                >
                    <p class="font-weight-medium ma-0 ">{{ $t('terms.termsAcceptedLink') }}</p>
                </a>
            </div>
        </div>
    </vue-perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import myActiveCoins from '@/components/likes/myActiveCoins'

export default {
    name: 'UserDrawer',
    components: { myActiveCoins },
    props: {
        isVisible: {
            type: Boolean,
            require: true,
            default: false
        }
    },
    data() {
        return {
            items: []
        }
    },
    watch: {
        isVisible(value) {
            if (value) {
                this.fetchMyStatist();
            }
        }
    },
    computed: {
        ...mapGetters(['getUser', 'isAdmin', 'getUserRoles', 'getUserAcronym', 'getUserPicture']),
        ...mapGetters('statistics', ['getMyStatistics', 'getMyLikes', 'getCoinsWhithoutSpend']),
        ...mapGetters('configGeneral', ['getConfigKeyValue'])
        /* getItems() {
            const values = [];
            if (this.getMyStatistics) {
                let totalPoints = 0;

                if (this.getMyLikes) {
                    const count = 4 - this.getCoinsWhithoutSpend;
                    const max = 4;
                    const points = count === 4 ? 5 : 0;

                    let progressvalue = max ? (count / max) * 100 : 0;
                    progressvalue = count > max ? 100 : progressvalue;

                    let progressColor = 'danger';
                    if (progressvalue >= 100) progressColor = 'secondary';
                    else if (progressvalue >= 75) progressColor = 'primary';

                    const item = {
                        letter: points,
                        title: this.$t('statistic.coins.votationComplete'),
                        subTitle: this.$t('statistic.coins.votationCompleteDesc'),
                        progressbarText: count > max ? count : `${count} - ${max}`,
                        progressvalue,
                        progressColor
                    };

                    values.push(item);
                    totalPoints += points;
                }

                // statistics for likes/dislike receive for my purposes
                if (this.getMyStatistics.myPurposesCloud) {
                    const obj = this.getMyStatistics.myPurposesCloud;

                    const progressvalue = 0;
                    let progressColor = 'danger'
                    if (obj.points >= 20) progressColor = 'secondary';
                    else if (obj.points >= 10) progressColor = 'primary';

                    const item = {
                        letter: obj.points,
                        title: this.$t('statistic.coins.myMissionCloud'),
                        subTitle: this.$t('statistic.coins.myMissionCloudDesc'),
                        progressbarText: '',
                        progressvalue,
                        progressColor,
                        likeItem: true
                    };
                    values.push(item);
                    totalPoints += obj.points;
                }

                // TOTAL
                const item = {
                    letter: totalPoints,
                    title: this.$t('statistic.coins.totalPoints'),
                    // subTitle: this.$t('statistic.coins.totalPointsDesc'),
                    progressbarText: '',
                    progressvalue: 0,
                    progressColor: 'primary',
                    totalItem: true
                };
                values.push(item);
            }

            return values;
        } */
    },
    mounted() {
        setTimeout(this.fetchMyStatist, 5000);
    },
    methods: {
        fetchMyStatist() {
            this.$store.dispatch('statistics/loadMyStatistics');
            this.$store.dispatch('statistics/loadMyLikes');
        },
        openStatistic() {
            // console.log("path", this.$route.path);
            if (this.$route.path !== "/app/pages/statistics") {
                this.$router.push('/app/pages/statistics');
            } else {
                this.$emit('close')
            }
            //
        }
    }
}
</script>

<style scoped></style>
